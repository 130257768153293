import React from "react";
import { graphql, Link } from "gatsby";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { ArticleLayout as Layout } from "../components/ArticleLayout";
import { Avatar } from "../components/Avatar";
import SEO from "react-seo-component";
import { useSiteMetadata } from "../hooks/use-site-metadata";
import CormacChisholm from "../assets/images/avatar.jpg";
import styled from "styled-components";
import {
  NewWindow,
  Anchor,
  StyledCode,
  SyntaxHighlighter,
  LiveCode,
} from "../components/";

/* eslint-disable */
const components = {
  code: (props) => {
    return <StyledCode {...props} />;
  },
  pre: (props) => {
    if (props.children.props["react-live"]) {
      return <LiveCode {...props} />;
    } else {
      return <SyntaxHighlighter {...props} />;
    }
  },
  a: (props) => {
    if (props["internal"] && props["anchor"]) {
      return (
        <Anchor {...props}>
          <span className="sr-only">Link to this heading</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="M13.723 18.654l-3.61 3.609c-2.316 2.315-6.063 2.315-8.378 0-1.12-1.118-1.735-2.606-1.735-4.188 0-1.582.615-3.07 1.734-4.189l4.866-4.865c2.355-2.355 6.114-2.262 8.377 0 .453.453.81.973 1.089 1.527l-1.593 1.592c-.18-.613-.5-1.189-.964-1.652-1.448-1.448-3.93-1.51-5.439-.001l-.001.002-4.867 4.865c-1.5 1.499-1.5 3.941 0 5.44 1.517 1.517 3.958 1.488 5.442 0l2.425-2.424c.993.284 1.791.335 2.654.284zm.161-16.918l-3.574 3.576c.847-.05 1.655 0 2.653.283l2.393-2.389c1.498-1.502 3.94-1.5 5.44-.001 1.517 1.518 1.486 3.959 0 5.442l-4.831 4.831-.003.002c-1.438 1.437-3.886 1.552-5.439-.002-.473-.474-.785-1.042-.956-1.643l-.084.068-1.517 1.515c.28.556.635 1.075 1.088 1.528 2.245 2.245 6.004 2.374 8.378 0l4.832-4.831c2.314-2.316 2.316-6.062-.001-8.377-2.317-2.321-6.067-2.313-8.379-.002z"
            />
          </svg>
        </Anchor>
      );
    } else if (props["internal"]) {
      return <NewWindow {...props} />;
    } else {
      return <NewWindow {...props} target="_blank" rel="noopener noreferrer" />;
    }
  },
};

const AuthorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 8px;
`;

const PublishedDetails = styled.div`
  margin: 2px 0;
  display: flex;

  p {
    margin: 0;
    font-size: 1rem;
  }
`;

const Author = styled(Link)`
  margin: 2px 0;
  font-weight: 700;
  width: max-content;
`;

const Spaced = styled.p`
  padding: 0 8px;
`;

export default function PostPage({ data }) {
  const {
    body,
    slug,
    excerpt,
    fields,
    frontmatter: { title, date, description },
  } = data.mdx;

  const {
    title: siteTitle,
    siteUrl,
    siteLanguage,
    siteLocale,
    twitterUsername,
    authorName,
  } = useSiteMetadata();

  return (
    <>
      <SEO
        title={title}
        titleTemplate={siteTitle}
        description={description ? description : excerpt}
        pathname={`${siteUrl}${slug}`}
        article={true}
        siteLanguage={siteLanguage}
        siteLocale={siteLocale}
        twitterUsername={twitterUsername}
        author={authorName}
        publishedDate={date}
        modifiedDate={new Date(Date.now()).toISOString()}
      />
      <Layout>
        {/* <Breadcrumb slug={slug} /> */}
        <h1>{title}</h1>
        <AuthorWrapper>
          <Avatar path={CormacChisholm} alt="picture of cormac chisholm" />
          <DetailsWrapper>
            <Author to="https://twitter.com/CormacChisholm" target="_blank">
              Cormac Chisholm
              <span className="sr-only">(opens in a new Tab)</span>
            </Author>
            <PublishedDetails>
              <p>{date}</p>
              <Spaced> · </Spaced>
              <p>{fields.readingTime.text}</p>
            </PublishedDetails>
          </DetailsWrapper>
        </AuthorWrapper>

        <MDXProvider components={components}>
          <MDXRenderer>{body}</MDXRenderer>
        </MDXProvider>
      </Layout>
    </>
  );
}

export const query = graphql`
  query POST_BY_SLUG($slug: String) {
    mdx(slug: { eq: $slug }) {
      id
      slug
      body
      excerpt(pruneLength: 120)
      frontmatter {
        date(formatString: "MMM Do, YYYY")
        title
        description
      }
      fields {
        readingTime {
          text
        }
      }
    }
  }
`;
