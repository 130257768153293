import * as React from "react";
import styled from "styled-components";

const Image = styled.img`
  border-radius: 50%;
  height: 45px;
  width: 45px;
`;

export const Avatar = ({ path, altText }) => {
  return <Image alt={altText} src={path} />;
};

export default Avatar;
